import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 2rem 0;

  @media(max-width: 871px) {
    flex-direction: column;
  }
`;

export const MainImageContainer = styled.div`
  width: 50%;

  @media(max-width: 871px) {
    width: 100%;
  }
`
export const TextContainer = styled.div`
  width: 50%;
  padding-left: 16px;

  @media(max-width: 871px) {
    width: 100%;
  }
`
