import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import SmallGallery from '../../components/smallGallery';
import { ContentSafeArea } from '../../components/globalComponents';
import SimpleReactLightbox from 'simple-react-lightbox';

import { Header, MainImageContainer, TextContainer } from './styles';

export const data = graphql`
    query($slug: String!, $galleryCategoryRegex: String!){
      mdx(frontmatter: { slug: { eq: $slug } }){
        frontmatter {
          title
          featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
        }
        body
      }
      allImages: allFile(filter: {relativeDirectory: {eq: "gallery"}, name: {regex: $galleryCategoryRegex}}) {
        edges {
          node {
            name
            childImageSharp {
              thumb: fluid(maxWidth: 270, maxHeight: 270) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              full: fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
          }
        }
      }
    }
  `

export default function ProductPage({data}) {

  const title = data.mdx.frontmatter.title
  const body = data.mdx.body
  const mainImage = data.mdx.frontmatter.featuredImage.childImageSharp.fluid

  return (
    <SimpleReactLightbox>
      <Layout>
        <ContentSafeArea>
          <Header>
            <MainImageContainer>
              <Img fluid={mainImage} />
            </MainImageContainer>
            <TextContainer>
              <h1> { title } </h1>
              <MDXRenderer>
                { body }
              </MDXRenderer>
            </TextContainer>
          </Header>
          { data.allImages.edges.length > 0 &&
            <>
              <h2>Serviços de { title.toLowerCase() }:</h2>
              <SmallGallery galleryImages={data.allImages} />
            </>
          }
        </ContentSafeArea>
      </Layout>
    </SimpleReactLightbox>
  )
}
