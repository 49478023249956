import React from 'react';
import Img from 'gatsby-image';
import { SRLWrapper } from 'simple-react-lightbox';

import { Gallery, GalleryImageWrapper } from './styles';


export default function SmallGallery({galleryImages}) {
  if (!galleryImages.edges.length){
    return (
      <></>
    )
  }
  return (
    <SRLWrapper>
      <Gallery>
        {galleryImages.edges.map(({node}) =>
          <GalleryImageWrapper>
            <a href={node.childImageSharp.full.src} data-attribute="SRL">
              <Img
                fluid={node.childImageSharp.thumb}
              />
            </a>
          </GalleryImageWrapper>
        )}
      </Gallery>
    </SRLWrapper>
  )
}
